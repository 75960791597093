import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

function Contact() {
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [ipAddress, setIpAddress] = useState('');
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);

    const [formData, setFormData] = useState({
        fullName: '',
        emailId: '',
        phoneNumber: '',
        messageContent: '',
        selectTopic: ''
    });

    const isFormValid = Object.values(formData).every(value => value.trim() !== '');

    const fetchIpAddress = async () => {
        try {
            // Using ipify to get the IP address
            const response = await axios.get('https://api.ipify.org?format=json');
            setIpAddress(response.data.ip);
        } catch (error) {
            console.error('Error fetching IP address:', error);
        }
    };

    const submitForm = async () => {

        if (isFormValid) {
            const submitformdata = new FormData()

            submitformdata.append('fullName', formData.fullName);
            submitformdata.append('emailId', formData.emailId);
            submitformdata.append('phoneNumber', formData.phoneNumber);
            submitformdata.append('topic', formData.selectTopic);
            submitformdata.append('messageContent', formData.messageContent);
            submitformdata.append('ipAddress', ipAddress);
            submitformdata.append('latitude', latitude);
            submitformdata.append('longitude', longitude);
            submitformdata.append('country', 'IN');

            const apiUrl = `https://enterprise-api.glancein.com/api/Website/insertContactFormdata`;

            axios.post(apiUrl, submitformdata, {
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'multipart/form-data'
                },
            }).then((response) => {
                // Update the state based on token validity
                if (response.data === "Ok") {
                    setFormData({
                        fullName: '',
                        emailId: '',
                        phoneNumber: '',
                        messageContent: '',
                        selectTopic: ''
                    })
                    alert('We have received your request and will get back to you shortly')
                } else {
                    alert(response.data);
                }
            }).catch((error) => {
                console.error(error);
            });
        }
        else {
            alert('Please Fill our all required fields')
        }
    }

    const fetchGeolocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                },
                (error) => {
                    console.error('Error fetching location:', error);
                }
            );
        } else {
            alert('Geolocation is not supported by this browser.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Set the state for the specific input field being changed
        setFormData({
            ...formData, // Spread the existing form data
            [name]: value, // Update the specific field with new value
        });

        //console.log(formData)
    };

    useEffect(() => {
        fetchGeolocation()
        fetchIpAddress()
        document.title = 'Contact - Glancein Enterprises Pvt Ltd';
    }, []);


    const handleCaptcha = (value) => {
        if (value) {

            const formdata = new FormData()

            formdata.append('captchatoken', value)

            const apiUrl = `https://enterprise-api.glancein.com/api/Website/verifycaptcha`;

            axios.post(apiUrl, formdata, {
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then((response) => {
                    // Update the state based on token validity
                    if (response.data.valid) {
                        setCaptchaVerified(true);
                        console.log('Captcha verified successfully:', response.data);
                    } else {
                        setCaptchaVerified(false);
                        console.log('Invalid captcha:', response.data);
                    }
                })
                .catch((error) => {
                    console.error('Error verifying captcha:', error);
                });
        } else {
            setCaptchaVerified(false);
        }
    };



    return (
        <div>
            <div class="breadcumb-wrapper">
                <div class="container">
                    <div class="breadcumb-content">
                        <h1 class="breadcumb-title">Contact Us</h1>
                        <ul class="breadcumb-menu">
                            <li><a href="/">Home</a></li>
                            <li>Contact Us</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="space">
                <div class="container">
                    <div class="row gy-30 gx-30">
                        <div class="col-md-6 col-xl-3 contact-info_wrapp">
                            <div class="contact-info">
                                <div class="contact-info_icon"><img src="../img/icon/address.svg" alt="" /></div>
                                <div class="media-body">
                                    <p class="contact-info_label">Address</p>
                                    <a href="https://www.google.com/maps" class="contact-info_link">Visakhapatnam
                                        <span>Andhra Pradesh</span></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-xl-3 contact-info_wrapp">
                            <div class="contact-info">
                                <div class="contact-info_icon"><img src="../img/icon/call-2.svg" alt="" /></div>
                                <div class="media-body">
                                    <p class="contact-info_label">Phone</p>
                                    <a href="tel:+58-125253158" class="contact-info_link">+91 99999 99999</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-xl-3 contact-info_wrapp">
                            <div class="contact-info">
                                <div class="contact-info_icon"><img src="../img/icon/mail-2.svg" alt="" /></div>
                                <div class="media-body">
                                    <p class="contact-info_label">Email</p>
                                    <a href="mailto:info@sassa.com" class="contact-info_link">contact@glancein.com</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-xl-3 contact-info_wrapp">
                            <div class="contact-info">
                                <div class="contact-info_icon"><img src="../img/icon/qus.svg" alt="" /></div>
                                <div class="media-body">
                                    <p class="contact-info_label">Have Questions?</p>
                                    <span class="contact-info_text">Discover more by visiting us or joining our community</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="space-bottom">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="">
                                <div class="contact-form style2 ajax-contact" style={{ maxHeight: 1000, borderColor: 'white' }}>
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <input type="text" placeholder="Your name*" name="fullName" value={formData.fullName} onChange={handleInputChange} class="form-control" />
                                            <i class="far fa-user"></i>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <input type="email" name="emailId" placeholder="Email address*" value={formData.emailId} onChange={handleInputChange} class="form-control" />
                                            <i class="far fa-envelope"></i>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <input type="number" class="form-control" value={formData.phoneNumber} onChange={handleInputChange} name="phoneNumber" id="number" placeholder="Phone number*" />
                                            <i class="fal fa-phone"></i>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <select name="selectTopic" id="subject" class="form-select nice-select" value={formData.selectTopic} onChange={handleInputChange}>
                                                <option value="" disabled selected hidden>Select Topic*</option>
                                                <option value="E-Commerce">E-Commerce</option>
                                                <option value="Quick Commerce">Quick Commerce</option>
                                                <option value="Intelli ERP">Intelli ERP</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-12">
                                            <textarea name="messageContent" id="message" value={formData.messageContent} onChange={handleInputChange} cols="30" rows="3" class="form-control" placeholder="Write a message*"></textarea>
                                            <i class="fal fa-pencil"></i>
                                        </div>
                                        <ReCAPTCHA
                                            sitekey="6Lc_xEUqAAAAACETWIlvEXL7pOQ5qK4PPp5OmFYo"
                                            onChange={handleCaptcha}
                                            style={{ marginBottom: 20 }}
                                        />
                                        <div class="col-12 form-group mb-0">
                                            <button title={!captchaVerified ? 'Please Verify you are human' : ''} style={!captchaVerified ? { backgroundColor: 'grey', cursor: 'not-allowed' } : null} disabled={!captchaVerified} class="th-btn btn-fw" onClick={submitForm}>Send Message</button>
                                        </div>
                                    </div>
                                    <p class="form-messages mb-0 mt-3"></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="contact-image style2">
                                <img src="../img/normal/contact-image.png" style={{ borderRadius: 30 }} alt="contact" />
                            </div>
                        </div>
                    </div>


                    {/* <div class="">
                        <div class="contact-map style2">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3644.7310056272386!2d89.2286059153658!3d24.00527418490799!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fe9b97badc6151%3A0x30b048c9fb2129bc!2sAngfuztheme!5e0!3m2!1sen!2sbd!4v1651028958211!5m2!1sen!2sbd" allowfullscreen="" loading="lazy"></iframe>
                            <div class="contact-icon">
                                <i class="fa-solid fa-location-dot"></i>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}


export default Contact;